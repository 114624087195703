.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__section-title, 
.react-autosuggest__suggestion {
  padding: 3px 0.75rem;
}

.react-autosuggest__section-title {
  font-style: italic;
  color: rgb(200, 200, 200);
  font-size: 0.9rem;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  font-size: 0.85rem;
}

.react-autosuggest__suggestion--highlighted {
  background-color: rgb(245, 245, 245);
}
