.react-hint {
	padding: 5px 0;
	position: absolute;
	z-index: 9999;
	cursor: default;
	animation: .3s fadeIn;
}

.react-hint__content {
	padding: 3px 3.5px;
	background: rgba(255,255,255,.95);
  color: rgb(125, 125, 125);
  border: 1px solid rgb(200, 200, 200);
	font-size: .8rem;
	max-width: 250px;
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}